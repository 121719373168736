// Generated by Framer (461de17)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./h7GPqgXTc-0.js";

const cycleOrder = ["FsHDZ17ll", "P0famSJI8", "XuvU1vAEv", "avLCcQNsn"];

const serializationHash = "framer-nhxhj"

const variantClassNames = {avLCcQNsn: "framer-v-1hcfle1", FsHDZ17ll: "framer-v-2v301i", P0famSJI8: "framer-v-tarbev", XuvU1vAEv: "framer-v-1aozz76"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {duration: 0, type: "tween"}

const transition2 = {delay: 0, duration: 4, ease: [0, 0, 1, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Static / Empty": "XuvU1vAEv", "Static / Filled": "avLCcQNsn", Empty: "FsHDZ17ll", Filled: "P0famSJI8"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "FsHDZ17ll"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "FsHDZ17ll", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearcs3trq = activeVariantCallback(async (...args) => {
setVariant("P0famSJI8")
})

useOnVariantChange(baseVariant, {avLCcQNsn: undefined, default: onAppearcs3trq, XuvU1vAEv: undefined})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "avLCcQNsn") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({P0famSJI8: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-2v301i", className, classNames)} data-framer-name={"Empty"} data-highlight layoutDependency={layoutDependency} layoutId={"FsHDZ17ll"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} {...addPropertyOverrides({avLCcQNsn: {"data-framer-name": "Static / Filled", "data-highlight": undefined}, P0famSJI8: {"data-framer-name": "Filled"}, XuvU1vAEv: {"data-framer-name": "Static / Empty", "data-highlight": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-jixi3e"} layoutDependency={layoutDependency} layoutId={"v2SXDVgX3"} style={{backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6}} variants={{avLCcQNsn: {backgroundColor: "rgb(255, 255, 255)"}}}><motion.div className={"framer-lcj848"} layoutDependency={layoutDependency} layoutId={"R1B4JIa_i"} style={{backgroundColor: "var(--token-1f6fdd3b-06fb-44b0-9f19-7f6a24d6a4b6, rgb(214, 39, 106))"}}/>{isDisplayed() && (<motion.div className={"framer-3q2654"} data-framer-name={"Static"} layoutDependency={layoutDependency} layoutId={"breId1Iqz"} style={{backgroundColor: "var(--token-1f6fdd3b-06fb-44b0-9f19-7f6a24d6a4b6, rgb(214, 39, 106))"}}/>)}<motion.div className={"framer-1lgoel"} layoutDependency={layoutDependency} layoutId={"yncIr5u2u"} style={{backgroundColor: "rgb(212, 212, 212)", opacity: 0.2}}/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-nhxhj.framer-90t9dq, .framer-nhxhj .framer-90t9dq { display: block; }", ".framer-nhxhj.framer-2v301i { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 4px; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: 27px; }", ".framer-nhxhj .framer-jixi3e { align-content: center; align-items: center; display: flex; flex: 1 0 0px; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 4px; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 1px; will-change: var(--framer-will-change-override, transform); }", ".framer-nhxhj .framer-lcj848 { flex: none; height: 4px; overflow: hidden; position: relative; width: 0%; }", ".framer-nhxhj .framer-3q2654 { bottom: 0px; flex: none; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; z-index: 3; }", ".framer-nhxhj .framer-1lgoel { bottom: 0px; flex: none; overflow: hidden; position: absolute; right: 0px; top: 0px; width: 100%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-nhxhj.framer-2v301i, .framer-nhxhj .framer-jixi3e { gap: 0px; } .framer-nhxhj.framer-2v301i > *, .framer-nhxhj .framer-jixi3e > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-nhxhj.framer-2v301i > :first-child, .framer-nhxhj .framer-jixi3e > :first-child { margin-left: 0px; } .framer-nhxhj.framer-2v301i > :last-child, .framer-nhxhj .framer-jixi3e > :last-child { margin-right: 0px; } }", ".framer-nhxhj.framer-v-tarbev.framer-2v301i, .framer-nhxhj.framer-v-1aozz76.framer-2v301i, .framer-nhxhj.framer-v-1hcfle1.framer-2v301i { width: 27px; }", ".framer-nhxhj.framer-v-tarbev .framer-lcj848 { width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 4
 * @framerIntrinsicWidth 27
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"P0famSJI8":{"layout":["fixed","fixed"]},"XuvU1vAEv":{"layout":["fixed","fixed"]},"avLCcQNsn":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerh7GPqgXTc: React.ComponentType<Props> = withCSS(Component, css, "framer-nhxhj") as typeof Component;
export default Framerh7GPqgXTc;

Framerh7GPqgXTc.displayName = "Story Pill";

Framerh7GPqgXTc.defaultProps = {height: 4, width: 27};

addPropertyControls(Framerh7GPqgXTc, {variant: {options: ["FsHDZ17ll", "P0famSJI8", "XuvU1vAEv", "avLCcQNsn"], optionTitles: ["Empty", "Filled", "Static / Empty", "Static / Filled"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerh7GPqgXTc, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})